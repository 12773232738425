window.dataLayer = window.dataLayer || []

const gtag = function() {
  window.dataLayer.push(arguments)
}

gtag('js', new Date())

gtag('config', process.env.REACT_APP_GOOGLE_MEASUREMENT_ID, {
  'send_page_view': false 
})

export default gtag