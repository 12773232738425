import React, { useState } from 'react'
import SearchBox from './SearchBox'

const GuessForm = ({ items, placeholder, onSubmit }) => {
  const [value, setValue] = useState('')

  const onSearchBoxChange = (event, { newValue }) => {
    setValue(newValue)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    onSubmit(value.trim())
    setValue('')
  }

  return (
    <form onSubmit={handleSubmit} className="flex w-full gap-2">
      <div className="w-3/4">
        <SearchBox
          value={value}
          items={items}
          maxSuggestions={6}
          placeholder={placeholder}
          onChange={onSearchBoxChange}
        />
      </div>
      <input type="submit" value="Submit" className="w-1/4 bg-emerald-700 text-white hover:cursor-pointer" />
    </form>
  )
}

export default GuessForm