import React from 'react'
import DarkModeToggle from './DarkModeToggle'
import About from './About'
import HowToPlay from './HowToPlay'

const Header = () => {
  return (
    <header className="flex justify-between items-center">
      <h1>{process.env.REACT_APP_NAME}</h1>
      <div className="flex gap-2 text-3xl items-center">
        <DarkModeToggle />
        <About />
        <HowToPlay />
      </div>
    </header>
  )
}

export default Header