import React from 'react'
import { useSelector } from 'react-redux'
import { game, selectGameOver, selectMask } from './game'
import MaskedImage from './components/MaskedImage'

const ImagePanel = () => {
  const gameOver = useSelector(selectGameOver)
  const mask = useSelector(selectMask)

  return (
    <div>
      {
        gameOver ?
          <a href={`https://wikipedia.org/wiki/${game.slug}`}>
            <MaskedImage
              src={`pictures/${game.picture}`}
              alt={game.solution}
            />
          </a> :
          <MaskedImage
            src={`pictures/${game.picture}`}
            alt="Mystery Person"
            mask={mask}
        />
      }
    </div>
  )
}

export default ImagePanel