import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle, faImage, faTableCells, faShareNodes } from '@fortawesome/free-solid-svg-icons'
import ModalOpener from './components/ModalOpener'
import { useSelector } from 'react-redux'
import { selectFirstVisit } from './game'

const HowToPlay = () => {
  const firstVisit = useSelector(selectFirstVisit)

  const title = "How to play"

  const content = (
    <ol className="grid grid-cols-1 gap-4">
      <li className="flex items-start">
        <FontAwesomeIcon icon={faImage} className="mt-1 mr-3" />
        <span>Try to guess the famous person in the partially covered picture.</span>
      </li>
      <li className="flex items-start">
        <FontAwesomeIcon icon={faTableCells} className="mt-1 mr-3" />
        <span>With each incorrect or skipped attempt, more of the picture is revealed.</span>
      </li>
      <li className="flex items-start">
        <FontAwesomeIcon icon={faShareNodes} className="mt-1 mr-3" />
        <span>Guess the person in as few attempts as possible and share your score!</span>
      </li>
    </ol>
  )

  return (
    <ModalOpener title={title} content={content} initiallyOpen={firstVisit}>
      <FontAwesomeIcon title={title} icon={faQuestionCircle} />
    </ModalOpener>
  )
}

export default HowToPlay