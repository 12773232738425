import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToggleOn, faToggleOff } from '@fortawesome/free-solid-svg-icons'
import { selectSettings, toggleDarkMode } from './game'

const DarkModeToggle = () => {
  const settings = useSelector(selectSettings)
  const dispatch = useDispatch()

  return (
    <FontAwesomeIcon
      title="Dark mode"
      icon={settings.darkMode ? faToggleOn : faToggleOff}
      onClick={() => dispatch(toggleDarkMode())}
      className="hover:cursor-pointer" />
  )
}

export default DarkModeToggle