import React from 'react'
import { useSelector } from 'react-redux'
import { game, nextGameTime, selectGuesses, selectMotivationalMessage, selectStatus, selectShareText } from './game'
import ShowHide from './components/ShowHide'
import GuessList from './components/GuessList'
import Status from './components/Status'
import CopyButton from './components/CopyButton'
import Countdown from './components/Countdown'

const ResultPanel = () => {
  const status = useSelector(selectStatus)
  const guesses = useSelector(selectGuesses)
  const motivationalMessage = useSelector(selectMotivationalMessage)
  const shareText = useSelector(selectShareText)

  return (
    <div className="grid grid-cols-1 gap-4 place-items-center">
      <a href={`https://wikipedia.org/wiki/${game.slug}`} className="no-underline">
        <h2>{game.solution}</h2>
      </a>
      <span>
        {motivationalMessage}
      </span>
      <ShowHide showText="Show guesses" hideText="Hide guesses">
        <GuessList guesses={guesses} />
      </ShowHide>
      <Status status={status} />
      <CopyButton
        text={shareText}
        label="Share"
        copiedLabel="Copied"
      />
      <span>Next person in <Countdown toDate={nextGameTime} /></span>
    </div>
  )
}

export default ResultPanel