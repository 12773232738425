import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { people, selectGuesses, selectGuessesRemaining, submitGuess } from './game'
import GuessForm from './components/GuessForm'
import GuessList from './components/GuessList'

const GuessPanel = () => {
  const guesses = useSelector(selectGuesses)
  const guessesRemaining = useSelector(selectGuessesRemaining)
  const dispatch = useDispatch()

  return (
    <div className="grid grid-cols-1 gap-4 place-items-center">
      <GuessForm
        items={people}
        placeholder="Search people"
        onSubmit={(guess) => dispatch(submitGuess(guess))}
      />
      <span>
        {`${guessesRemaining} ${guessesRemaining > 1 ? 'guesses' : 'guess'} remaining`}
      </span>
      <GuessList guesses={guesses} />
    </div>
  )
}

export default GuessPanel