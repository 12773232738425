import React, { useState } from 'react'

const ShowHide = ({ showText, hideText, children }) => {
  const [visible, setVisible] = useState(false)

  const toggle = () => setVisible(!visible)

  return (
    <div className="grid grid-cols-1 gap-4 place-items-center">
      <button onClick={toggle} className="border-0 p-0 underline">{visible ? hideText : showText}</button>
      {visible && children}
    </div>
  )
}

export default ShowHide