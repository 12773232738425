import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX, faCheck } from '@fortawesome/free-solid-svg-icons'

const GuessList = ({ guesses }) => {
  const getGuessIcon = (guess) => {
    if (guess.success) {
      return <FontAwesomeIcon icon={faCheck} className="text-green-600" />
    }
    else if (guess.skip) {
      return <FontAwesomeIcon icon={faX} className="text-neutral-600" />
    }
    else {
      return <FontAwesomeIcon icon={faX} className="text-red-600" />
    }
  }

  return (
    <ol className="grid grid-cols-1 gap-4 w-full">
      {
        guesses.map((guess, index) => (
          <li key={`guesslist-guess-${index}`} className="border rounded p-2">
            <span>{getGuessIcon(guess)}</span>
            <span className="ml-1">{guess.guess}</span>
          </li>
        ))
      }
    </ol>
  )
}

export default GuessList