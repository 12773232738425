import { createSlice, createSelector, configureStore } from '@reduxjs/toolkit'
import people from './people.json'
import games from './games.json'

// The day of the first game
const startDate = new Date(2022, 4, 10)

// Today at midnight
const currentDayMidnight = new Date()
currentDayMidnight.setHours(0, 0, 0, 0)

// Number of milliseconds in a single day
const millisecondsPerDay = 24 * 60 * 60 * 1000

// Calculate how many days have passed since the first game
const elapsedMilliseconds = currentDayMidnight - startDate
const elapsedDays = Math.round(elapsedMilliseconds / millisecondsPerDay)

// The current game number
const gameNumber = elapsedDays + 1

// The time when the next game begins
const nextGameTime = new Date(currentDayMidnight.getTime() + millisecondsPerDay)

// If the games are exhausted wrap around to the start
const gameIndex = (gameNumber - 1) % games.length

// The current game
const game = games[gameIndex]

// The default settings for the game
const defaultSettings = {
  darkMode: true
}

// The slice of state holding the current game number
const gameNumberSlice = createSlice({
  name: 'gameNumber',
  initialState: gameNumber,
  reducers: {}
})

// The slice of state holding the guesses for the current game
const guessesSlice = createSlice({
  name: 'guesses',
  initialState: [],
  reducers: {
    addGuess: (state, action) => {
      state.push(action.payload)
    }
  }
})

// The slice of state holiding the game settings
const settingsSlice = createSlice({
  name: 'settings',
  initialState: defaultSettings,
  reducers: {
    toggleDarkMode: (state, action) => {
      state.darkMode = !state.darkMode
    }
  }
})

// Action to submit a guess
const submitGuess = guess => (dispatch) => {
  // Add the guess to the list of guesses
  dispatch(guessesSlice.actions.addGuess(guess))
}

// Action to toggle dark mode
const { toggleDarkMode } = settingsSlice.actions

// Retrieve any persisted game state in the user's local storage
const persistedState = localStorage.getItem('gameState') && JSON.parse(localStorage.getItem('gameState'))

// Is it the user's first visit to the site
const firstVisit = !persistedState

// Build the initial state
const initialState = {
  gameNumber,
  guesses: persistedState && persistedState.gameNumber === gameNumber ? persistedState.guesses : [],
  settings: persistedState && persistedState.settings ? persistedState.settings : defaultSettings
}

// Construct the redux store, initialising it with any game state
const store = configureStore({
  reducer: {
    gameNumber: gameNumberSlice.reducer,
    guesses: guessesSlice.reducer,
    settings: settingsSlice.reducer
  },
  preloadedState: initialState
})

// When the game state changes, save it back to local storage
store.subscribe(() => {
  localStorage.setItem('gameState', JSON.stringify(store.getState()))
})

// Select if is the user's first visit to site
const selectFirstVisit = () => firstVisit

// Select the current game number
const selectGameNumber = (state) => state.gameNumber

// Does a guess match the solution
const successfulGuess = (guess) => guess.toLowerCase() === game.solution.toLowerCase()

// Select the user guesses
const selectGuesses = (state) => state.guesses.map(guess => {
  return {
    guess,
    success: successfulGuess(guess),
    skip: !guess
  }
})

// Select the settings
const selectSettings = (state) => state.settings

// Calculate the current image mask state based on the number of guesses submitted so far
// The image mask state is a list of booleans indicating whether the corresponding cell in the mask is revealed or not
// True if a cell is revealed, false otherwise
const selectMask = createSelector(selectGuesses, (guesses) => {
  return game.reveal.map(r => r <= guesses.length)
})

// Select whether the user has solved the game
// The game is solved if some guess matches the solution
const selectSuccess = createSelector(selectGuesses, (guesses) => {
  return guesses.some(guess => guess.success)
})

// The maximum allowed number of guesses
const maxGuesses = 6

// The number of guesses the user has remaining
const selectGuessesRemaining = createSelector(selectGuesses, (guesses) => {
  return maxGuesses - guesses.length
})

// Select whether the game is over
// The game is over if the user guessed successfully or if the guesses were exhausted
const selectGameOver = createSelector(selectSuccess, selectGuesses, (success, guesses) => {
  return success || guesses.length >= maxGuesses 
})

// A motivational message to show to the user
const selectMotivationalMessage = createSelector(selectGameOver, selectSuccess, selectGuesses, (gameOver, success, guesses) => {
  if (!gameOver) {
    return 'Keep going!'
  }

  if (!success) {
    return 'Better luck tomorrow!'
  }

  switch (guesses.length) {
    case 0:
      return 'That\'s just impossible!'
    case 1:
      return 'Have you x-ray vision?'
    case 2:
      return 'Sensational!'
    case 3:
      return 'Amazing!'
    case 4:
      return 'Awesome!'
    case 5:
      return 'Nice going!'
    case 6:
    default:
      return 'Phew! That was close!'
  }
})

// Select the game status
// The game status shows how each of the user's guesses were used
// 0 = incorrect guess, 1 = correct guess, 2 = unused guess
const selectStatus = createSelector(selectGuesses, (guesses) => {
  return Array.from({ length: maxGuesses }, (_, i) => i).map(i => {
    if (i < guesses.length) {
      const guess = guesses[i]

      if (guess.success) {
        return 1
      }
      else if (guess.skip) {
        return 2
      }
      else {
        return 0
      }
    }
    else {
      return 3
    }
  })
})

const selectSuccessEmoji = createSelector(selectSuccess, (success) => {
  return success ? '🤩' : '😖'
})

// A text representation of the game status
const selectStatusText = createSelector(selectStatus, (status) => {
  return status.map((cell) => {
    switch (cell) {
      case 0:
        return '🟥'
      case 1:
        return '🟩'
      case 2:
        return '⬛'
      case 3:
      default:
        return '⬜️'
    }
  }).join('')
})

// A textual representation of the game result, designed to be shared with others
const selectShareText = createSelector(selectGameNumber, selectSuccessEmoji, selectStatusText, (gameNumber, successEmoji, statusText) => {
  return `${process.env.REACT_APP_NAME} #${gameNumber}\n${successEmoji}${statusText}\n${process.env.REACT_APP_URL}\n`
})

export {
  people,
  game,
  nextGameTime,
  store,
  submitGuess,
  toggleDarkMode,
  selectFirstVisit,
  selectGameNumber,
  selectGuesses,
  selectSettings,
  selectGuessesRemaining,
  selectMask,
  selectSuccess,
  selectGameOver,
  selectMotivationalMessage,
  selectStatus,
  selectShareText
}
