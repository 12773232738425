import React, { useState } from 'react'
import Modal from 'react-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/free-solid-svg-icons'

Modal.setAppElement('#root')

const ModalOpener = ({ title, content, initiallyOpen, children }) => {
  const [open, setOpen] = useState(initiallyOpen)

  const doOpen = () => {
    setOpen(true)
  }

  const doClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <div onClick={doOpen} className="hover:cursor-pointer">
        {children}
      </div>
      <Modal isOpen={open} onRequestClose={doClose} className="absolute top-16 left-1/2 transform -translate-x-1/2 p-6 w-4/5 lg:w-1/3 bg-white border rounded border-black focus:outline-0 dark:bg-black dark:text-white dark:border-white">
        <div className="grid grid-cols-1 gap-6">
          <div className="flex justify-between">
            <h2>{title}</h2>
            <FontAwesomeIcon icon={faX} onClick={doClose} className="hover:cursor-pointer" />
          </div>
          <div>{content}</div>
        </div>    
      </Modal>
    </div>
  )
}

export default ModalOpener