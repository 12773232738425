import { useEffect } from 'react'

const addBodyClass = (className) => document.body.classList.add(className)

const removeBodyClass = (className) => document.body.classList.remove(className)

const useBodyClass = (className) => {
  useEffect(() => {
    addBodyClass(className)

    return () => {
      removeBodyClass(className)
    }
  })
}

export default useBodyClass