import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import ModalOpener from './components/ModalOpener'

const About = () => {
  const title = "About"

  const content = (
    <div className="grid grid-cols-1 gap-4">
      <p>
        A game inspired by <a href="https://www.nytimes.com/games/wordle/index.html">Wordle</a>
        , <a href="https://worldle.teuteuf.fr/">Worldle</a>
        , <a href="https://www.heardle.app/">Heardle</a>
        , <a href="https://framed.wtf/">Framed</a> and <a href="https://medium.com/floodgates/the-complete-and-authoritative-list-of-wordle-spinoffs-fb00bfafc448">all the other great spin-offs</a>.
      </p>
      <p>
        All images come from <a href="https://commons.wikimedia.org/wiki/Main_Page">Wikimedia Commons</a>. Some images 
        have been cropped to fit the game.
      </p>
      <p>
        The list of famous people comes from the <a href="https://pantheon.world/">Pantheon</a> project.
      </p>
      <p>
        The application is built with <a href="https://reactjs.org/">React</a>
        , <a href="https://redux.js.org/">Redux</a>
        , <a href="https://tailwindcss.com/">Tailwind</a>
        , <a href="https://fontawesome.com/">Font Awesome</a>, and powered by <a href="https://vercel.com/">Vercel</a>.
        The version is {process.env.REACT_APP_VERSION}.
      </p>
    </div>
  )

  return (
    <ModalOpener title={title} content={content}>
      <FontAwesomeIcon title={title} icon={faInfoCircle} />
    </ModalOpener>
  )
}

export default About