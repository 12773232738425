import React, { useState } from 'react'
import Autosuggest from 'react-autosuggest'

const SearchBox = ({ value, items, maxSuggestions, placeholder, onChange }) => {
  const [suggestions, setSuggestions] = useState([])

  const normalise = (str) => {
    return str.trim()
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
  }

  const onSuggestionsFetchRequested = ({ value }) => {
    const normalisedValue = normalise(value)

    const matches = items.reduce((acc, item) => {
      const v = normalise(item)

      if (v === normalisedValue) {
        return [item, ...acc]
      }
      else if (v.includes(normalisedValue)) {
        return [...acc, item]
      }
      else {
        return acc
      }
    }, []).slice(0, maxSuggestions)

    setSuggestions(matches)
  }

  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  const getSuggestionValue = suggestion => suggestion

  const renderSuggestion = (suggestion) => {
    return (
      <span>{suggestion}</span>
    )
  }

  const inputProps = {
    value,
    placeholder,
    onChange,
    spellCheck: false
  }

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
    />
  )
}

export default SearchBox