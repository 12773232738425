import React from 'react'

const MaskedImage = ({ src, alt, mask }) => {
  const cellColors = [
    'bg-neutral-100',
    'bg-neutral-200',
    'bg-neutral-300',
    'bg-neutral-400',
    'bg-neutral-500',
    'bg-neutral-600',
    'bg-neutral-700'
  ]

  const cellColor = (i) => cellColors[i % cellColors.length]

  return (
    <div className="relative aspect-square border rounded">
      <img className="aspect-square w-full rounded" src={src} alt={alt} />
      {
        mask && <div className={`absolute top-0 left-0 grid grid-cols-10 w-full h-full`}>
          {
            mask.map((cell, index) => (
              <div key={`maskedimage-cell-${index}`} className={`${cellColor(index)} ${cell ? 'opacity-0' : 'opacity-100'}`} />
            ))
          }
        </div>
      }
    </div>
  )
}

export default MaskedImage