import React, { useState } from 'react'

const CopyButton = ({ text, label, copiedLabel }) => {
  const [copied, setCopied] = useState(false)

  const handleClick = () => {
    navigator.clipboard.writeText(text).then(() => { 
      setCopied(true)
      setTimeout(() => {
        setCopied(false)
      }, 2000)
    })
  }

  return (
    <button onClick={handleClick} className="bg-emerald-700 text-white" >
      {copied ? copiedLabel : label}
    </button>
  )
}

export default CopyButton