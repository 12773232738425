import React from 'react'
import { createRoot } from 'react-dom/client'
import { store } from './game'
import { Provider } from 'react-redux'
import gtag from './gtag'
import App from './App'
import './index.css'

const root = createRoot(document.getElementById('root'))

root.render(
  <Provider store={store}>
    <App />
  </Provider>
)

gtag('event', 'page_view')
