import React from 'react'
import { useSelector } from 'react-redux'
import { selectGameOver, selectSettings } from './game'
import useBodyClass from './useBodyClass'
import Header from './Header'
import ImagePanel from './ImagePanel'
import GuessPanel from './GuessPanel'
import ResultPanel from './ResultPanel'

const App = () => {
  const gameOver = useSelector(selectGameOver)
  const settings = useSelector(selectSettings)

  useBodyClass(settings.darkMode ? 'dark' : 'light')

  return (
    <div className="dark:bg-black dark:text-white min-h-screen">
      <div className="grid grid-cols-1 font-sans text-xl">
        <div className="grid grid-cols-1 p-4 gap-4 border-b border-slate-300 lg:grid-cols-12 lg:p-8 lg:gap-8">
          <div className="lg:col-span-10">
            <Header />
          </div>
        </div>
        <div className="grid grid-cols-1 p-4 gap-4 lg:grid-cols-12 lg:p-8 lg:gap-8">
          <div className="lg:col-span-5">
            <ImagePanel />
          </div>
          <div className="lg:col-span-5">
            { gameOver ? <ResultPanel /> : <GuessPanel /> }
          </div>
        </div>
      </div>
    </div>
  )
}

export default App