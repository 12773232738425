import React, { useState, useEffect } from 'react'

const formatTime = (milliseconds) => {
  const seconds = Math.floor((milliseconds / 1000) % 60)
  const minutes = Math.floor((milliseconds / (1000 * 60)) % 60)
  const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24)

  const hoursString = (hours < 10) ? '0' + hours.toString() : hours.toString();
  const minutesString = (minutes < 10) ? '0' + minutes.toString() : minutes.toString();
  const secondsString = (seconds < 10) ? '0' + seconds.toString() : seconds.toString();

  return hoursString + ':' + minutesString + ':' + secondsString
}

const calculateMillisecondsTo = (someDate) => {
  const currentDate = new Date()
  const diff = someDate.getTime() - currentDate.getTime()

  return Math.max(0, diff)
}

const Countdown = ({ toDate }) => {
  const [millisecondsToGo, setMillisecondsToGo] = useState(calculateMillisecondsTo(toDate))

  useEffect(() => {
    setInterval(() => {
      setMillisecondsToGo(calculateMillisecondsTo(toDate))
    }, 1000)
  }, [toDate])

  return (
    <span>{formatTime(millisecondsToGo)}</span>
  )
}

export default Countdown