import React from 'react'

const Status = ({ status }) => {
  const cellBackgroundColor = (cell) => {
    switch (cell) {
      case 0:
        return 'bg-red-600'
      case 1:
        return 'bg-green-600'
      case 2:
        return 'bg-neutral-600'
      case 3:
      default:
        return 'bg-neutral-200'
    }  
  }

  return (
    <div className="flex gap-1">
      {
        status.map((cell, index) => (
          <span key={`status-cell-${index}`} className={`aspect-square rounded w-8 h-8 ${cellBackgroundColor(cell)}`}></span>
        ))
      }
    </div>
  )
}

export default Status